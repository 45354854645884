/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

import defaultOGPImage from "../images/ogpImage.png"

function SEO({title, ogpImage}) {
  
  const { pathname } = useLocation()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            shortName
            siteLanguage
            domain
          }
        }
      }
    `
  )

  const seo = {
    title: site.siteMetadata.title,
    description: site.siteMetadata.description,
    image: ogpImage || defaultOGPImage,
    url: `${site.siteMetadata.url}${pathname}`,
  }

  var schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "@id": `${seo.url}`,
      url: `${seo.url}`,
      name: `${seo.title} | ${site.siteMetadata.title}`,
      alternateName: site.siteMetadata.shortName,
    },
  ]

  return (
    <Helmet
      htmlAttributes={seo.siteLanguage}
      title={seo.title}
    >
    {title && <title>{title} | {site.siteMetadata.title}</title>}
    {!title && <title>{site.siteMetadata.title}</title>}

    <meta name="description" content={seo.description} />
      <meta name="image" content={`${site.siteMetadata.url}${seo.image}`} />
      <meta itemProp="image" content={`${site.siteMetadata.url}${seo.image}`} />
      <meta
        name="apple-mobile-web-app-title"
        content={site.siteMetadata.shortName}
      />
      <meta name="application-name" content={site.siteMetadata.shortName} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OGP */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={`${site.siteMetadata.url}${seo.image}`} />
      <meta property="og:site_name" content={site.siteMetadata.shortName} />
      <meta property="fb:admins" content="644333355" />
      <meta property="og:description" content={seo.description} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:domain" content={site.siteMetadata.domain} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={`${site.siteMetadata.url}${seo.image}`} />

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `jp`,
  meta: [],
  description: ``,
  article: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.bool,
}

export default SEO
