/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"

import NGCIlogo from "../images/democraticDay-title-horizontal.svg"
import FBIcon from "../images/header_icon_facebook.svg"
import HatenaIcon from "../images/header_icon_hatena.svg"
import TwitterIcon from "../images/header_icon_twitter.svg"

// CSSモジュール
import * as global from "../components/global.module.css"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          url
        }
      }
    }
  `)

  const location = useLocation()
  const href = `${data.site.siteMetadata.url}${location.pathname}`
  const titleText = (title) ? `${data.site.siteMetadata?.title} | ${title}` : `${data.site.siteMetadata?.title}`

  let fbLink = encodeURI(`https://www.facebook.com/share.php?u=${href}`)
  let hbLink = encodeURI(`http://b.hatena.ne.jp/entry/${href}`)
  let twLink = encodeURI(`https://twitter.com/intent/tweet?text=${titleText}&url=${href}`)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} fbLink={fbLink} hbLink={hbLink} twLink={twLink} >
      </Header>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `100%`,
          width: `100%`,
          padding: 0,
          overflow: `hidden`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `0`,
            backgroundColor: `#06274B`,
            color: `white`,
          }}
        >
          <div className={`${global.footerFrame}`}>
            <figure className={`${global.NGCIlogo}`}>
              <Link to="/"><svg viewBox={NGCIlogo.viewBox}><use xlinkHref={NGCIlogo.url}/></svg></Link>
              <ul className={`${global.logos}`}>
                <li><a href="https://cyber.nakao-lab.org/" target="_blank" rel="noreferrer"><StaticImage src="../images/logo_NGCI.png" alt="NGCI" layout="fixed" placeholder="NGCI" /></a></li>
                <li><a href="https://www.u-tokyo.ac.jp/adm/fsi/ja/symposium.html" target="_blank" rel="noreferrer"><StaticImage src="../images/logo_FSI.png" alt="UTokyo FSI Symposium" layout="fixed" placeholder="UTokyo FSI Symposium" /></a></li>
                <li><a href="https://digital-days.digital.go.jp" target="_blank" rel="noreferrer"><StaticImage src="../images/logo_JapanDigitalDay.png" alt="2021年デジタルの日" layout="fixed" placeholder="2021年デジタルの日" /></a></li>
              </ul>
              <p>主催：次世代サイバーインフラ連携研究機構<br />後援：次世代サイバーインフライニシアティブ</p>
            </figure>

            <div className={`${global.footerElem}`}>
              <ul className={`${global.footerText}`}>
                <li><Link to="/">Concept</Link></li>
                <li><Link to="/">WebEvent OverView</Link></li>
                <li><Link to="/">Program</Link></li>
                {/* <li><Link to="/">Speakers</Link></li> */}
              </ul>

              <ul className={`${global.footerIcons}`}>
                <li className={`${global.snsIcon} fb`}><a href={fbLink} target="_blank" rel="noreferrer" aria-label="Facebook"><svg viewBox={FBIcon.viewBox}><use xlinkHref={FBIcon.url}/></svg></a></li>
                <li className={`${global.snsIcon} hatena`}><a href={hbLink} target="_blank" rel="noreferrer" aria-label="はてなブックマーク"><svg viewBox={HatenaIcon.viewBox}><use xlinkHref={HatenaIcon.url}/></svg></a></li>
                <li className={`${global.snsIcon} tw`}><a href={twLink} target="_blank" rel="noreferrer" aria-label="Twitter"><svg viewBox={TwitterIcon.viewBox}><use xlinkHref={TwitterIcon.url}/></svg></a></li>
              </ul>

              <p className={`${global.footerCopyright}`}>copyright © {new Date().getFullYear()} NGCI Democratic Day All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
