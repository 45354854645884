// extracted by mini-css-extract-plugin
export var header = "global-module--header--N9_Vk";
export var buttonFrame = "global-module--buttonFrame--1dYJe";
export var menuButton = "global-module--menuButton--3k7Ae";
export var NGCILogo = "global-module--NGCILogo--3LVOd";
export var menuFrame = "global-module--menuFrame--36Xsv";
export var snsIcon = "global-module--snsIcon--hMBHc";
export var TokyoUnivLogo = "global-module--TokyoUnivLogo--3heWr";
export var footerFrame = "global-module--footerFrame--3pFW4";
export var NGCIlogo = "global-module--NGCIlogo--l93vV";
export var footerElem = "global-module--footerElem--3SQKq";
export var logos = "global-module--logos--cv-FO";
export var footerIcons = "global-module--footerIcons--BwcUN";
export var footerText = "global-module--footerText--1O6dM";
export var footerCopyright = "global-module--footerCopyright--Q9DNs";