import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import NGCIlogo from "../images/democraticDay-title-horizontal.svg"
import TokyoUnivLogo from "../images/tokyoUniv-logo.svg"
import FBIcon from "../images/header_icon_facebook.svg"
import HatenaIcon from "../images/header_icon_hatena.svg"
import TwitterIcon from "../images/header_icon_twitter.svg"

// CSSモジュール
import * as global from "../components/global.module.css"

const Header = ({ siteTitle, fbLink, hbLink, twLink }) => (
  <header className={`${global.header}`}>
    <div className={`${global.buttonFrame}`}>
      <figure className={`${global.NGCILogo}`}>
        <Link to="/"><svg viewBox={NGCIlogo.viewBox}><use xlinkHref={NGCIlogo.url}/></svg></Link>
      </figure>

      <figure className={`${global.TokyoUnivLogo}`}>
        <a href="https://www.u-tokyo.ac.jp/ja/index.html" target="_blank" rel="noreferrer" aria-label="東京大学"><svg viewBox={TokyoUnivLogo.viewBox}><use xlinkHref={TokyoUnivLogo.url}/></svg></a>
      </figure>

      <figure className={`${global.menuButton}`}>
        <div>
            <span></span>
            <span></span>
            <span></span>
        </div>
      </figure>
    </div>

    <ul className={`${global.menuFrame}`}>
        <li><Link to="/#concept">Concept</Link></li>
        <li><Link to="/#overview">WebEvent OverView</Link></li>
        <li><Link to="/program/">Program</Link></li>
        {/* <li><Link to="/#speakers">Speakers</Link></li> */}

        <li className={`${global.snsIcon} fb`}><a href={fbLink} target="_blank" rel="noreferrer" aria-label="Facebook"><svg viewBox={FBIcon.viewBox}><use xlinkHref={FBIcon.url}/></svg></a></li>
        <li className={`${global.snsIcon} hatena`}><a href={hbLink} target="_blank" rel="noreferrer" aria-label="はてなブックマーク"><svg viewBox={HatenaIcon.viewBox}><use xlinkHref={HatenaIcon.url}/></svg></a></li>
        <li className={`${global.snsIcon} tw`}><a href={twLink} target="_blank" rel="noreferrer" aria-label="Twitter"><svg viewBox={TwitterIcon.viewBox}><use xlinkHref={TwitterIcon.url}/></svg></a></li>
    </ul>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
